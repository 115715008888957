import { SystemStyleObject } from '@styled-system/css';

const gridTemplateAreas = [
  [ '"primary"' ], // one tile
  [
    // two tiles
    `"primary"
    "secondary"`,

    `"primary"
    "secondary"`,

    '"primary secondary"',
  ],
  [
    // three tiles
    `"primary"
    "secondary"
    "tertiary"`,

    `"primary"
    "secondary"
    "tertiary"`,

    `"primary secondary"
    "primary tertiary"`,
  ],
];

const containerHeight = 480;
const containerHalfHeight = (containerHeight - 16) / 2;

const gridTemplateRows = [
  [ `${containerHeight}px` ], // one tile
  [
    `${containerHeight}px ${containerHalfHeight}px`,
    `${containerHeight}px ${containerHalfHeight}px`,
    '1fr',
  ], // two tiles
  [
    `${containerHeight}px ${containerHalfHeight}px ${containerHalfHeight}px`,
    `${containerHeight}px ${containerHalfHeight}px ${containerHalfHeight}px`,
    '1fr 1fr',
  ], // three tiles
];

const gridTemplateColumns = [
  null,
  [ null, null, '1fr 0.5fr' ],
  [ null, null, '1fr 0.5fr' ],
];

export function getContainerStyles(length: number): SystemStyleObject {
  return {
    display: 'grid',
    gap: 'xs',
    marginY: 'xs',
    gridTemplateAreas: gridTemplateAreas[length - 1],
    gridTemplateRows: gridTemplateRows[length - 1],
    gridTemplateColumns: gridTemplateColumns[length - 1],
  };
}

type HeightSet = [number, number, number];

export function getTileHeights(length: number): HeightSet[] {
  const fullHeightSet: HeightSet = [
    containerHeight,
    containerHeight,
    containerHeight,
  ];
  const halfHeightSet: HeightSet = [
    containerHalfHeight,
    containerHalfHeight,
    containerHalfHeight,
  ];

  return [
    [ fullHeightSet ], // one tile
    [ fullHeightSet, fullHeightSet ], // two tiles
    [ fullHeightSet, halfHeightSet, halfHeightSet ], // three tiles
  ][length - 1];
}

export type TileVariant = 'large' | 'medium' | 'small';

export const getTileVariant = (
  campaignsToRender: number,
  index: number,
): TileVariant => {
  if (index === 0) {
    return 'large';
  }

  if (campaignsToRender === 2) {
    return 'medium';
  }

  return 'small';
};
