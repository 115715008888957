import React, { FC, ComponentProps, useMemo } from 'react';

import { MultiPromotionTile } from './MultiPromotionTile';
import { MultiPromotionTileFooterLeft } from './MultiPromotionTileFooterLeft';
import { MultiPromotionTileFooterRight } from './MultiPromotionTileFooterRight';
import { MultiPromotionTileHeader } from './MultiPromotionTileHeader';
import { PromoCampaign } from './types';
import { getContainerStyles, getTileHeights, getTileVariant } from './utils';

const positions = [ 'primary', 'secondary', 'tertiary' ];

type TileProps = Pick<ComponentProps<typeof MultiPromotionTile>, 'TileClickTracking' | 'imageSettings'>;

type MultiPromotionProps = {
  campaigns: PromoCampaign[];
} & TileProps;

/**
 * MultiPromotion component renders up to three MultiPromotionTile components
 */
export const MultiPromotion: FC<MultiPromotionProps> = ({
  campaigns,
  imageSettings,
  TileClickTracking,
}) => {
  const campaignsToRender = useMemo(
    () => (campaigns ? campaigns.slice(0, 3) : []),
    [ campaigns ],
  );

  return (
    <div
      sx={getContainerStyles(campaignsToRender.length)}
      data-id="multi-promo"
    >
      {campaignsToRender.map((campaign, index) => (
        <MultiPromotionTile
          key={index}
          index={index}
          height={getTileHeights(campaignsToRender.length)[index]}
          background={campaign.background}
          foregroundImage={campaign.foregroundImage}
          url={campaign.url}
          TileClickTracking={TileClickTracking}
          imageSettings={imageSettings}
          sx={{
            gridArea: positions[index],
            minWidth: 0,
            minHeight: 0,
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
            }}
          >
            {campaign.header && (
              <MultiPromotionTileHeader
                {...campaign.header}
                variant={getTileVariant(campaignsToRender.length, index)}
              />
            )}
            {campaign.footer && (
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                {campaign.footer.left && (
                  <MultiPromotionTileFooterLeft {...campaign.footer.left} />
                )}
                {campaign.footer.right && (
                  <MultiPromotionTileFooterRight {...campaign.footer.right} />
                )}
              </div>
            )}
          </div>
        </MultiPromotionTile>
      ))}
    </div>
  );
};
