import React, { FC } from 'react';

import { PromoCampaignFooterRight } from './types';
import { Icon, Image } from '@Components';
import { Colors, IncrementSizes } from '@Tokens';

export const MultiPromotionTileFooterRight: FC<
PromoCampaignFooterRight
> = ({
  color, icon, image, size,
}) => (
  <div
    sx={{
      flexShrink: 0,
      minWidth: '0',
    }}
  >
    {icon && (
      <Icon
        name={icon}
        size={(size as keyof IncrementSizes) ?? '24'}
        color={(color as keyof Colors) ?? 'backgroundWhite'}
        sx={{
          filter: 'drop-shadow(0, 0, 5px rgba(0, 0, 0, 0.5))',
        }}
      />
    )}
    {image?.url && (
      <Image
        src={image.url}
        alt={image.description}
        width={Number(size) || 100}
        fluid={false}
        lazy={false}
      />
    )}
  </div>
);
