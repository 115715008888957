import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { SxStyleProp } from 'theme-ui';

import { HeroImage } from './HeroImage';
import { PromoCampaign } from './types';
import { HydrateWhenInViewport } from '@AuxiliaryComponents';
import { Image, Link } from '@Components';

const constrainedHeroBannerStyles: SxStyleProp = {
  display: 'block',
  height: '100%',
  filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))',
  textDecoration: 'none',
  padding: 's',
};

const getOverlayOpacity = (overlayType?: string) => {
  switch (overlayType) {
    case 'shadow':
      return 0.64;
    case 'fill':
      return 0.4;
    default:
      return 0;
  }
};

type PromoCampaignProps = Pick<PromoCampaign, 'background' | 'foregroundImage' | 'url'>;

type TileClickTrackingProps = { action: string; children: ReactElement };

type MultiPromotionTileProps = PropsWithChildren<PromoCampaignProps & {
  height: [number, number, number];
  index: number;
  className?: string;
  TileClickTracking: FC<TileClickTrackingProps>;
  imageSettings?: {
    dpr?: number;
    quality?: number;
  };
}>;

export const MultiPromotionTile: FC<MultiPromotionTileProps> = ({
  className,
  background,
  foregroundImage,
  url,
  children,
  height,
  index,
  TileClickTracking,
  imageSettings,
}) => {
  const foregoundImageSizes = [ height[2] * 0.5, height[2] * 0.75 ];
  const isFirstChild = index === 0;
  const WrapperComponent = isFirstChild ? 'div' : HydrateWhenInViewport;
  const shouldLazyLoad = !isFirstChild;

  return (
    <WrapperComponent
      data-id="multi-promo-tile"
      className={className}
      sx={{
        background: background?.color,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '12',
        ...(url && {
          '&:hover': {
            '.hero-image': {
              transform: 'scale(1.04)',
            },
            '.foreground-image': {
              transform: 'translate(-50%, -50%) scale(1.04)',
            },
            '.multiPromoCta': {
              '&::after': {
                width: '100%',
              },
            },
            '.overlay': {
              '&::before': {
                opacity: background?.overlayType === 'fill' ? 0.55 : 0.72,
              },
            },
          },
        }),
      }}
    >
      {background?.image && (
        <HeroImage
          className="hero-image"
          sx={{
            width: '100%',
            objectFit: 'cover',
            overflow: 'hidden',
            transition: 'transform 400ms ease-in-out',
            '& > section': {
              height: '100%',
              width: '100%',
            },
          }}
          image={background.image}
          height={height}
          fit="bounds"
          lazy={shouldLazyLoad}
          {...imageSettings}
        />
      )}
      <div
        className="overlay"
        sx={{
          position: 'absolute',
          inset: 0,
          borderRadius: '12',

          '&::before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            // linear gradient transition are not supported directly
            // you can achieve the same effect by playing with
            // the opacity on a pseudo-element
            transition: 'opacity 400ms ease-in-out',
            opacity: getOverlayOpacity(background?.overlayType),
            background:
              background?.overlayType === 'fill'
                ? 'rgba(0, 0, 0, 1.00)'
                : 'linear-gradient(180deg, rgba(0, 0, 0, 1.00) 0%, rgba(0, 0, 0, 0.00) 100%)',
          },
        }}
      >
        {foregroundImage && (
          <Image
            className="foreground-image"
            src={foregroundImage.url}
            alt={foregroundImage.description}
            lazy={shouldLazyLoad}
            height={foregoundImageSizes[1]}
            width={foregoundImageSizes[1]}
            fluid={false}
            sx={{
              position: 'absolute',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              transition: 'transform 400ms ease-in-out',
              maxWidth: foregoundImageSizes,
              maxHeight: foregoundImageSizes,
            }}
          />
        )}
        {url ? (
          <TileClickTracking action={`promo-campaign${index}`}>
            <Link
              href={url}
              internal
              sx={{ ...constrainedHeroBannerStyles }}
            >
              {children}
            </Link>
          </TileClickTracking>
        ) : (
          <div sx={constrainedHeroBannerStyles}>{children}</div>
        )}
      </div>
    </WrapperComponent>
  );
};
