import React, { FC } from 'react';

import { PromoCampaignFooterLeft } from './types';
import { DontHydrate } from '@AuxiliaryComponents';
import { Icon, Label } from '@Components';
import { Colors, Icons } from '@Tokens';

const FooterLeft: FC<PromoCampaignFooterLeft> = ({
  icon,
  color,
  text,
}) => (
  <DontHydrate
    sx={{
      color: color ?? 'white',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {icon && (
      <Icon
        name={icon}
        size="24"
        color={color as keyof Colors | undefined}
        sx={{
          filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 1))',
        }}
      />
    )}

    <Label
      variant="mediumbold"
      sx={{
        overflow: 'hidden',
        maxHeight: '2.4em',
        lineHeight: '1.2em',
        paddingX: '3xs',
        textShadow: '0px 0px 5px black',
      }}
    >
      {text}
    </Label>
  </DontHydrate>
);

export const MultiPromotionTileFooterLeft: React.FC<
PromoCampaignFooterLeft
> = ({ icon, color, text }) => {
  if (!text) {
    return <span />;
  }

  return (
    <DontHydrate
      sx={{
        flexShrink: 0,
        minWidth: '0',
        maxWidth: '60%',
      }}
    >
      <FooterLeft
        icon={icon as Icons}
        color={color as keyof Colors}
        text={text}
      />
    </DontHydrate>
  );
};
